<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>数据排行榜</a-breadcrumb-item>
            <a-breadcrumb-item>邀约到访分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visible">
                        <template slot="content">
                            <div class="more-search">
                            <a-form layout='inline'>
                                <a-form-item class="block-line" label="来源渠道">
                                    <a-tree-select
                                      v-model="paramsData.channel_id"
                                      :tree-data="parentChannels"
                                      tree-checkable
                                      allowClear
                                      treeNodeFilterProp="title"
                                      :maxTagCount='1'
                                      :maxTagTextLength='5'
                                      :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                      :show-checked-strategy="SHOW_ALL"
                                      placeholder="请选择来源渠道"
                                  />
                                </a-form-item>
                                <a-form-item class="block-line" label="所属校区">
                                  <a-select v-model="paramsData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                  </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="添加人">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="toScreen">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                
                <statistics :count='counts' :col="6"/>

                <a-row class="mb16" :gutter="16">
                  
                    <a-col :lg="24" :xl="24">
                      <!-- <VElines :loading='loading' title="邀约到访数据统计"  :item='incomeDate' :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> -->
                      <goal :loading='loading' :item ='incomeDate' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>


                    <a-col :lg="24" :xl="24">
                      <a-card size="small" title="员工邀约统计">
                        <!-- <a-button slot="extra" size="small" icon="export">导出</a-button> -->
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
                        :columns="col_teacher_book_columns" :dataSource="col_teacher_book" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <a-card size="small" title="校区邀约统计">
                        <!-- <a-button slot="extra" size="small" icon="export">导出</a-button> -->
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                        :columns="col_studio_book_columns" :dataSource="col_studio_book" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card> 
                    </a-col>


                    <a-col :lg="24" :xl="12">
                      <LRanking title="校区承诺到访排行" :loading='loading' :rank_data='studioLeadsData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="校区实际到访排行" :loading='loading' :rank_data='studioLeadsAttendData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="承诺到访排行" :loading='loading' :rank_data='leadsData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="实际到访排行" :loading='loading' :rank_data='leadsAttendData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <!-- <a-col :lg="24" :xl="12">
                      <LRanking title="校区线索邀约率排行" :loading='loading' :rank_data='invited_studio_precent' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="线索邀约率排行" :loading='loading' :rank_data='invited_precent' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col> -->
                
                    <a-col :lg="24" :xl="12">
                      <LRanking title="渠道承诺到访排行" :loading='loading' :rank_data='channelData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                      <LRanking title="渠道实际到访排行" :loading='loading' :rank_data='attendData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="渠道大类承诺到访排行" :loading='loading' :rank_data='channelCateData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                      <LRanking title="渠道大类实际到访排行" :loading='loading' :rank_data='attendCateData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import statistics from '@/views/bi/ranking/money/statistics'
import goal from './goal'
export default {
  name: 'invite',
  components:{
    goal,
    statistics
  },
  data() {
    return {
      visible:false,
      loading:false,
      leadsData:{},
      col_teacher_book:[],
      col_teacher_book_columns:[],
      col_studio_book:[],
      col_studio_book_columns:[],
      leadsAttendData:{},
      studioLeadsData:{},
      studioLeadsAttendData:{},
      channelData:{},
      attendData:{},
      channelCateData:{},
      attendCateData:{},
      incomeDate:{},
      invited_precent:{},
      invited_studio_precent:{},
      counts:[],
      studios:[],
      classCategorys:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        channel_id:undefined,
        filter_type:'day',
      },
      parentChannels:[],
      channelsId:[],
      staffList:[]
    }
  },
  mixins: [ranges],
  computed: {
      RelativeWidth() {
          let width = 850
          if(this.filedLength >10){
          width = 900 + ( this.filedLength - 10 ) * 130
          }
          return width
      }
  },
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
    this.getStaff()
    this.getClassCategory()
    this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisRankingInvitedAction', obj)
      this.leadsData = res.data.invited
      this.leadsAttendData=res.data.invited_attend
      this.studioLeadsData = res.data.invited_studio
      this.studioLeadsAttendData=res.data.invited_studio_attend
      this.channelData=res.data.invited_channel
      this.attendData=res.data.invited_channel_attend
      this.incomeDate=res.data.invited_count
      this.invited_precent = res.data.invited_precent
      this.invited_studio_precent = res.data.invited_studio_precent
      this.channelCateData=res.data.invited_channel_cate
      this.attendCateData=res.data.invited_channel_cate_attend


      let single_col_date = 
       [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '员工名称', width:'160px', dataIndex: 'employee_name',  key: 'employee_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'120px', dataIndex: 'employee_invited_total', key: 'employee_invited_total',fixed: 'left',align:'right'},
      ]
      if(res.data.invited_employee_amount){
          res.data.invited_employee_amount.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
              single_col_date.push(obj)
          })
      }

      this.col_teacher_book_columns=single_col_date;
      this.col_teacher_book = res.data.invited_employee_amount.rows;


      let studio_col_date = 
       [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'120px', dataIndex: 'studio_invited_total', key: 'studio_invited_total',fixed: 'left',align:'right'},
      ]
      if(res.data.invited_studio_amount){
          res.data.invited_studio_amount.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
              studio_col_date.push(obj)
          })
      }


      this.col_studio_book_columns=studio_col_date;
      this.col_studio_book = res.data.invited_studio_amount.rows;

      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.classCategorys = res.data
    },
    async getSourceChannel() {
        let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
        this.parentChannels = res.data
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    handleSubjectChange(value){
      this.paramsData.course_cate_id = value;
    },
    handleChange(value){
      this.paramsData.studio_id = value
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    },
    closeSearch() {
      this.visible = false
    },
    reset(){
      this.paramsData.channel_id = undefined
      this.channelsId = []
      this.paramsData.studio_id = undefined
      this.paramsData.created_by = undefined
    },
    handleSearch(val){
        this.getStaff(val)
    },
  }
}
</script>