<template>

    <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <ve-histogram
                :data="item" 
                :grid="{left:'2%',right: '2%',top:'20%'}"
                :legend="{bottom:10}"
                judge-width
                :title="{
                    text:`邀约到访（${item.title?item.title:'0'}）`,
                    subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
                    textStyle: {
                        fontSize: 15,
                    },
                    subtextStyle: {
                        fontSize: 12.5
                    }
                }"
                :settings="{
                    label: { 
                        show: true, 
                        position: 'top',
                    },
                    yAxisType: ['KMB', 'percent'],
                    axisSite: { right: ['到访率','到访签约率'] }, 
                    showLine: ['到访率','到访签约率'],
                    areaStyle:{
                        area: true,
                        normal:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: '#effffb' // 0% 处的颜色
                                }, {
                                    offset: 0, color: '#23d9ae' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                            shadowColor: 'rgba(0, 0, 0, 0.1)',
                            shadowBlur: 10
                        }
                    },
                }" 
                :extend="histogramChartExtend"
                
                
                ></ve-histogram>
            <LEmpty v-if="first>0 && item.rows.length == 0"/>
        </div>
    </a-card>

</template>

<script>





    export default {
        name:"goal",
        data () {
            this.markLine = {
                symbol: ['none', 'none'], //去掉箭头
                data: [
                    {
                    name: '平均线',
                    type: 'average'
                    }
                ]
            }
            return{
                first:0,
                histogramChartExtend:{
                    series(item){
                        if(item){
                            item[0].barMaxWidth = 10
                            item[0].itemStyle = Object.assign({}, {
                                normal:{
                                    color: function(params) {
                                        return {
                                            type: 'linear',
                                            x:1,
                                            y:1,
                                            x2:0,
                                            y2:0,
                                            colorStops: [{
                                                offset: 0,
                                                color: '#ffffff'
                                                
                                            }, {
                                                offset: 1,                    
                                                color: '#23d9ae'
                                            }],
                                        }
                                    },
                                    barBorderRadius:[30,30,0,0]
                                }
                            })
                            item[1].barMaxWidth = 10
                            item[1].itemStyle = Object.assign({}, {
                                normal:{
                                    color: function(params) {
                                        console.log(params)
                                        // params.color = '#F8AC59'
                                        return {
                                            type: 'linear',
                                            x:1,
                                            y:1,
                                            x2:0,
                                            y2:0,
                                            colorStops: [{
                                                offset: 0,
                                                color: '#ffffff'
                                                
                                            }, {
                                                offset: 1,                    
                                                color: '#F8AC59'
                                                // color: params.color
                                            }],
                                        }
                                    },
                                    barBorderRadius:[30,30,0,0]
                                }
                            })
                            item[2].itemStyle = Object.assign({}, {
                                color: '#3275FB',
                            })
                            item[2].symbolSize="8"
                            item[2].symbol="circle"
                            item[2].smooth=false
                            item[2].label = Object.assign({}, {
                                show:false,
                                position: "top",
                                color:'#000',
                                formatter:function(value,index){
                                    return `${ Number((value.data * 100).toFixed(2)) } %`
                                }
                            })


                            // item[2].itemStyle = Object.assign({}, {
                            //     color: '#3275FB',
                            // })
                            item[3].symbolSize="8"
                            item[3].symbol="circle"
                            item[3].smooth=false
                            item[3].label = Object.assign({}, {
                                show:false,
                                position: "top",
                                color:'#000',
                                formatter:function(value,index){
                                    return `${ Number((value.data * 100).toFixed(2)) } %`
                                }
                            })
                        }
                        return item
                    },
                    xAxis:{
                        axisLine:{
                            show:true,
                            lineStyle: {
                                type: 'dotted',
                            }
                        },
                    },
                    yAxis(item){
                        item[0].axisLine = Object.assign({}, {
                            show:false
                        })
                        item[1].axisLine = Object.assign({}, {
                            show:false
                        })
                        item[0].splitLine = Object.assign({}, {
                            show:true,
                            lineStyle: {
                                type: 'dotted'
                            }
                        })
                        item[1].splitLine = Object.assign({}, {
                            show:false,
                            lineStyle: {
                                type: 'dotted'
                            }
                        })
                        return item
                    },
                }
            }
        },
        props:['item', 'loading','start_date','end_date'],
        watch: {
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>